.authSection {
  background-color: #d6e6f3;
  padding: 30px;
  height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
}

.authSection-left {
  text-align: center;
}

.authSection-left img {
  margin-bottom: 30px;
  width: 60%;
}

.authSection-left button {
  border: none;
  border-radius: 1.5rem;
  padding: 6px 35px;
  /* background: #f8f9fa; */
  font-weight: bold;
  height: auto;
  line-height: normal;
  font-size: 14px;
}

.authSection-right {
  background: #f8f9fa;
  border-top-left-radius: 10% 50%;
  border-bottom-left-radius: 10% 50%;
  padding: 30px 50px 30px 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.authSection-right .authSection-heading {
  text-align: center;
  margin-bottom: 30px;
}

.authSection .lang_button{
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 99;
}

.react-tel-input .form-control{
  padding-right: 45px;
}

@media screen and (max-width: 1200px) {
  .authSection-right {
    padding: 30px 50px 30px 50px;
  }
}

@media screen and (max-width: 991px) {
  .authSection-left img {
    margin-bottom: 40px;
    width: 80%;
  }
}

@media screen and (max-width: 991px) {
  .authSection-left {
    margin: 50px 0;
  }

  .authSection-left img {
    margin-bottom: 40px;
    width: 80%;
    max-width: 250px;
  }
}

@media screen and (max-width: 767px) {
  .authSection {
    padding: 30px 0 0 0;
    display: block;
    height: auto;
  }

  .authSection-right {
    display: block;
    border-bottom-left-radius: 0;
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
  }
}

@media screen and (max-width: 575px) {
  .authSection-right {
    padding: 30px;
  }
}

	/* style for webview only */
  .authSection-left-webview {
    text-align: center;
    padding: 20px 0;
  }
  .authSection-left-webview img {
    margin-bottom: 0;
    width: 150px;
    height: auto;
  }
  .form-button-webview {
    border-radius: 1.2rem;
    line-height: 1;
  }
  /* new style */
.main-wrapper{
  margin: 0 auto;
  padding: 0 10px;
  max-width: 1440px;
}
.header{
  padding: 20px 0;
}
.header .main_logo{
  width: 170px;
  height: auto;
}
.border_btn{
  border: 1px solid #FF4014;
  border-radius: 5px;
  color: #FF4014;
  font-size: 14px;
  padding: 9px 17px;
  line-height: normal;
  height: auto;
  font-weight: bold;
  background-color: #ffffffcc;
}
.solid_btn1{
  background-color: #9654FF;
  border-radius: 5px;
  color: #ffffff;
  font-size: 14px;
  padding: 10px 17px;
  line-height: normal;
  height: auto;
  font-weight: bold;
  border: 0;
  transition: all 0.2s ease-in-out,
}
.solid_btn2{
  background-color: #5DCCDA;
  border-radius: 5px;
  color: #ffffff;
  font-size: 14px;
  padding: 10px 17px;
  line-height: normal;
  height: auto;
  font-weight: bold;
  border: 0;
  transition: all 0.2s ease-in-out,
}
.btn_square{
  padding: 10px;
}
.border_btn:hover , .solid_btn1:hover , .solid_btn2:hover {
  box-shadow: 0px 0px 10px #00000029;
}
.auth_area{
  margin-top: 30px;
  padding-bottom: 20px;
}
.auth_area h2{
  color: #3C3C3C;
  font-weight: bold;
  line-height: normal;
  font-size: 36px;
  margin: 0;
}
.auth_area h3{
  color: #3C3C3C;
  font-weight: bold;
  line-height: normal;
  font-size: 28px;
  margin: 0;
}
.auth_area h5{
  color: #3C3C3C;
  font-weight: bold;
  line-height: normal;
  font-size: 18px;
  margin: 0;
}
.auth_area h6{
  color: #3C3C3C;
  font-weight: bold;
  line-height: normal;
  font-size: 16px;
  margin: 0;
}
.auth_area p{
  font-size: 14px;
}
.auth_area ::-webkit-scrollbar {
  width: 1px;
}
.auth_area_left_col{
  margin-left: 5%;
}
.md_down_display_none{
  display:block;
}
.md_up_display_none{
  display:none;
}
.auth_area .react-tel-input .form-control{
  width: 100%;
  height: 40px;
  font-size: 16px;
  line-height: 1.5;
  border-radius: 8px;
}
.auth_area :where(.css-dev-only-do-not-override-3nf8hw).ant-input-outlined.ant-input-disabled,.auth_area :where(.css-dev-only-do-not-override-3nf8hw).ant-input-outlined[disabled]{
  background: #ffffff;
}
.addon_card img{
  width: 50px;
   height: auto;
}
@media screen and (max-width: 992px) {
  .addon_card{
    display: grid;
  }
  .addon_card img{
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 767px) {
  .header{
    padding: 10px 0;
  }
  .header .main_logo{
    width: 125px;
  }
  .auth_area_left_col{
    margin-left: 0;
    padding: 0 10px;
  }
  .md_down_display_none{
    display: none;
  }
  .md_up_display_none{
    display:block;
  }
  .auth_area h2{
    font-size: 28px;
  }
  .auth_area h3{
    font-size: 22px;
  }
  .auth_area h5{
    font-size: 16px;
  }
  .auth_area h6{
    font-size: 14px;
  }
  .auth_area p , .auth_area span, .auth_area div{
    font-size: 12px;
  }
  .auth_area .react-tel-input .form-control{
    height: 34px;
    font-size: 12px;
  }
}